import React from 'react';
import Typewriter from 'typewriter-effect' ;
import myPic1 from "../myPic1.jpg"

import linkedin from "../linkedin.svg";
import github from "../github.svg";

function MyHeader(){


	return (

		<div className="MyHeader">

			<div className="MyHeader-typewriter">
				<Typewriter
					assName = "header" 
					onInit = {(typewriter) =>
						{ 

							let greetingList = ['Hola' , "Здравствуйте" , "Bonjour", "你好", "안녕하세요",  'こんにちは', "Hallo", "Γεια σου" ,'Hallå' ]  
							let i = 0;

							let random = Math.floor(Math.random() *   greetingList.length);
							let initLength = greetingList.length;
							console.log("greet len"+initLength);

							typewriter.typeString("Hello").pause(2000).deleteAll();
							for(;i<3; i++){

								typewriter.typeString(greetingList[random]).pause(2000).deleteAll();

								//greetingList.pop(random);
								greetingList.splice(random,1);
								
								console.log("random "+random);
								random = Math.floor(Math.random() *   greetingList.length);
							
							}  

							typewriter.typeString("Hello").start();


						}
					}
				/>


			</div>	
			<br></br>

			<div className="MyHeader-Outer-Image" >
				<img className="MyHeader-Image" src={myPic1} width="300px" height="300px" ></img>

			</div>
			<div className="MyHeader-Name">

				<h1> Alexander Cadoret</h1>

			</div>
			<div className="MyHeader-Profession">

				<h2> <span className="not-bold">Software Engineer</span>  </h2>

				<a href ="https://www.linkedin.com/in/alexander-cadoret-c3po/"  >
                                         <img src={linkedin } width="40" height="40" />
    			</a>
                <span> </span>
 
				<a href="https://github.com/zor4k">
					<img src={github} width="40" height="40" />
				</a>


			</div>


		</div>


		);

}

export default MyHeader
