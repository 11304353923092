import React from 'react'


function AboutMe(){

    return(
        <div className="AboutMe">
            <h1 className="AboutMe-Title">About Me</h1>
          <p className="AboutMe-Content">
	I'm a professional software engineer who received my bachelor's degree in computer science from Florida Atlantic University.
	I'm interested in full stack development, machine/deep learning, and cryptocurrencies.
	I have experience in developing applications using different technologies and programming langagues like:
	<ul>
		<li>Python3</li>
		<li>Java</li>
		<li>TypeScript</li>
		<li>C++</li>
		<li>React</li>
		<li>express.js</li>
		<li>Nginx</li>
		<li>MySQL</li>	
		<li>Azure</li>
		<li>Digial Ocean</li>
		<li>Linux(Ubuntu and Debian)</li>

	</ul>
	
		
	</p>
            


        </div>
        

    );

}

export default AboutMe;
