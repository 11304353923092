import './App.css';
import React, {useState, useEffect} from 'react';


import MyHeader from './components/MyHeader';
import AboutMe from './components/AboutMe';

function App() {
  return ( 
    <div>
	<MyHeader />
	<AboutMe />

	
    </div>

  );
}

export default App;
